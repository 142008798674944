import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Image } from "react-bootstrap"

import spotifyBadge from "../images/spotify-badge.svg"
import appleBadge from "../images/apple-badge.svg"

const IndexPage = () => (
  <Layout>
    <Seo title="Vibe Chat Podcast" />
    {/* <Container className="d-lg-flex heroWidth">
        <div lg={6} className="">
          <h1></h1>
          
        </div>
        <div className="">
          <StaticImage
            src="../images/child-white-shirt.jpg"
            loading="eager"
            width={350}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Child in white shirt talking into a microphone"
          />
        </div>
    </Container> */}
    <div className="masthead mb-5">
      <Container className="singleCol">
        <h1>Vibe Chat Podcast</h1>
      </Container>
    </div>
    <Container className="singleCol ">
    {/* <p className="float-img float-none float-md-start me-md-3">
        <StaticImage 
          src="../images/kris-langley.jpg"
          alt="Michael Kaufman"
          placeholder="blurred"
          className="float-img text-center"
        /> */}
      {/* </p> */}
      <p className="text-center">Follow wherever you listen to your podcasts:</p>
      <p className="text-center"><a  href="https://open.spotify.com/show/3XG54slxs2Ny0SDvHUfdJW">
              <Image src={spotifyBadge} style={{maxWidth:'150px'}} className="me-3" fluid alt="Spotify Badge" />
            </a>
            <a href="https://podcasts.apple.com/us/podcast/vibe-chat/id1633245223">
              <Image src={appleBadge} style={{maxWidth:'150px'}} fluid alt="Apple Badge" />
            </a>
            </p>
            <hr />
      <p className="lead text-center">
      <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameborder="0" height="450" width="100%" style={{maxWidth:'800px',height:'450px'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" title="podcast" src="https://embed.podcasts.apple.com/us/podcast/vibe-chat/id1633245223"></iframe>
      
      </p>
      <p>
        Join Kris Langley, M.A., M.S. CCC-SLP as she delves into all aspects of communication. Practical and insightful discussion about how we communicate individually and collectively. Speech and language therapy strategies, techniques and mindfulness are woven into each episode. You will learn when and how to remedy speech and language skills, and how to enhance communication in all areas of life. Life expands and becomes more joyful as we improve our communication skills, no matter what age we are! Kris is a licensed speech therapist with an MA in Integral Counseling Psychology, and has a dedicated practice in mind/body connection, mantra and meditation.
      </p>
      
    </Container>
    


  </Layout>
)

export default IndexPage
